<template>

  <div>
    <div v-show="evaluationPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="breadcrumbs-top">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <h1 class="custom-header-title">
                Preview Annual Vendor Evaluation {{ aveForm.period }}
              </h1>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <section class="invoice-preview-wrapper">
        <b-row
          class="invoice-preview"
        >

          <!-- Col: Left (Invoice Container) -->
          <b-col
            cols="12"
            xl="9"
            md="8"
          >
            <div>
              <b-card
                class="mb-0"
              >
                <b-row class="invoice-preview">
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-left"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == 1"
                      @click="changeFileIndex(currentFileIndex - 1)"
                    >
                      <feather-icon
                        icon="ChevronLeftIcon"
                        class="pt-25"
                        size="18"
                      />
                      <span class="align-middle">Prev</span>
                    </b-button>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-center"
                  >
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      split
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FileIcon"
                          size="18"
                          class="align-middle mr-1"
                        />
                        <span class="mr-1">{{ currentFileIndex.toString().padStart(2, "0") }} / {{ attachments ? ((attachments.length + 1).toString().padStart(2, "0")) : '01' }}: {{ selectedFileName }}</span>
                      </template>
                      <b-dropdown-item @click="changeFileIndex(1)">
                        Annual Vendor Evaluation {{ aveForm.period }}
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-for="(file, key) in attachments"
                        :key="key"
                        @click="changeFileIndex(key + 2)"
                      >
                        {{ file ? file.name : '' }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <p
                      v-show="currentFileIndex == 1"
                      class="mt-1"
                    >
                      Preview Annual Vendor Evaluation {{ aveForm.period }}
                    </p>
                    <p
                      v-show="currentFileIndex != 1"
                      class="mt-1"
                    >
                      {{ attachments.length ? (attachments[currentFileIndex - 2] ? attachments[currentFileIndex - 2].description : '') : '' }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    xl="4"
                    md="4"
                    class="text-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="link"
                      :disabled="currentFileIndex == (attachments ? (attachments.length + 1) : 1)"
                      @click="changeFileIndex(currentFileIndex + 1)"
                    >
                      <span class="align-middle">Next</span>
                      <feather-icon
                        icon="ChevronRightIcon"
                        class="pt-25"
                        size="18"
                      />
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </div>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <div v-show="showAttachment">
                <iframe
                  v-show="showPDF"
                  id="attachmentpdf"
                  width="100%"
                  height="1145"
                />
                <b-img
                  v-show="!showPDF"
                  :src="imageSrc"
                  fluid-grow
                  alt="Fluid-grow image"
                />
              </div>
              <div v-show="!showAttachment">
                <iframe
                  id="docpdf"
                  width="100%"
                  height="1145"
                />
              </div>
            </b-card>
          </b-col>

          <!-- Right Col: Card -->
          <b-col
            cols="12"
            md="4"
            xl="3"
            class="invoice-actions"
          >
            <b-card>

              <template #header>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="flat-primary"
                  class="primary-button-with-background mr-3"
                >
                  <feather-icon
                    icon="GridIcon"
                    class=""
                    size="20"
                  />
                </b-button>
                <h3 class="align-middle mr-auto">
                  <span class="step-class">Approval Required</span>
                  Ready to Submit?
                </h3>
              </template>

              <b-card-text class="mb-0">
                <span>
                  If the information on this Annual Vendor Evaluation is accurate, please proceed to confirm to e-sign.
                </span>
                <br>
                <span>It will then be sent to the relevant parties for e-signing after.</span>
              </b-card-text>

              <b-form-checkbox
                v-model="secondAgree"
                value="accepted"
                unchecked-value=""
                class="custom-control-primary"
              >
                I confirm that all information is accurate on this form.
              </b-form-checkbox>

              <!-- Button: Send Invoice -->
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mb-75"
                block
                :disabled="secondAgree == ''"
                @click="finalConfirmation()"
              >
                <feather-icon
                  icon="FeatherIcon"
                  class="mr-50"
                  size="16"
                />
                <span class="align-middle">Sign and Submit</span>
              </b-button>

              <!-- Button: DOwnload -->
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="enableEdit()"
              >
                Back to Editing
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="mb-75"
                block
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </div>
    <div v-show="!evaluationPreview">
      <b-row
        class="content-header"
      >

        <!-- Content Left -->
        <b-col
          class="content-header-left mb-2"
          cols="12"
          md="12"
        >
          <b-row class="create-edit-form">
            <b-col
              class="content-header-left mb-2"
              cols="12"
              md="12"
            >
              <h1 class="custom-header-title evaluation-title">
                Annual Vendor Evaluation – Scoring ({{ aveForm.period }})
              </h1>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-form @submit.prevent="submitForm">
        <validation-observer
          ref="vendorEvaluationForm"
          #default="{invalid}"
        >
          <b-card
            no-body
            header-tag="header"
          >
            <template #header>
              <h6 class="align-middle ml-auto">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.modal-evaluation-guide
                  type="button"
                  variant="link"
                >
                  <feather-icon
                    icon="ListIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Evaluation Guide</span>
                </b-button>
              </h6>
            </template>
            <b-card-body class="zero-padding p-0">
              <div class="group__bottom">
                <b-table-simple
                  ref="vendorsTable"
                  class="position-relative has_padding evaluation_form"
                  responsive
                  hover
                  sticky-header="500px"
                >
                  <b-thead head-variant="light">
                    <b-tr>
                      <b-th
                        width="1%"
                        :sticky-column="true"
                      >
                        No
                      </b-th>
                      <b-th
                        width="18%"
                        class="text-left"
                        :sticky-column="true"
                        style="width:227px; min-width:227px"
                      >
                        Vendor
                      </b-th>
                      <b-th
                        width="30%"
                        class="text-left"
                        style="min-width:668px;width:668px; max-width:668px;"
                      >
                        <b-row>
                          <b-col
                            cols="12"
                            md="7"
                            style="width:380px; min-width:380px; max-width:380px"
                          >
                            <span>Supplied Item Description</span>
                          </b-col>
                          <b-col
                            cols="12"
                            md="1"
                            style="min-width:68px;width:68px;max-width:68px"
                          >
                            <span>Unit</span>
                          </b-col>
                          <b-col
                            cols="12"
                            md="2"
                            style="min-width:100px;width:100px; max-width:100px"
                          >
                            <span>Unit Price<br>(S$)</span>
                          </b-col>
                          <b-col
                            cols="12"
                            md="2"
                            style="min-width:120px;width:120px; max-width:120px"
                          >
                            <span>Adj Unit<br>price</span>
                          </b-col>
                        </b-row>
                      </b-th>
                      <b-th
                        width="7%"
                        class="text-left"
                        style="min-width:143px;width:143px; max-width:143px"
                      >
                        Credit term<br>(days)
                      </b-th>
                      <b-th
                        width="7%"
                        class="text-left"
                        style="min-width:129px;width:129px; max-width:129px"
                      >
                        Late delivery<br>(days)
                      </b-th>
                      <b-th
                        width="7%"
                        class="text-left"
                        style="min-width:129px;width:129px; max-width:129px"
                      >
                        Late delivery<br>(times)
                      </b-th>
                      <b-th
                        width="7%"
                        class="text-left"
                        style="min-width:70px;width:70px; max-width:70px"
                      >
                        No. of<br>NCF
                      </b-th>
                      <b-th
                        width="7%"
                        class="text-left"
                        style="min-width:95px;width:95px; max-width:95px"
                      >
                        adj unit<br>price
                      </b-th>
                      <b-th
                        width="7%"
                        class="text-left"
                        style="min-width:95px;width:95px; max-width:95px"
                      >
                        Credit<br>term
                      </b-th>
                      <b-th
                        width="7.14%"
                        style="min-width:95px;width:95px; max-width:95px"
                        class="text-left"
                      >
                        Delivery/<br>service
                      </b-th>
                      <b-th
                        width="7.14%"
                        class="text-left"
                        style="min-width:70px;width:70px; max-width:70px"
                      >
                        Quality
                      </b-th>
                      <b-th
                        width="7.14%"
                        class="text-left"
                        style="min-width:75px;width:75px; max-width:75px"
                      >
                        total<br>points
                      </b-th>
                      <b-th
                        width="7.14%"
                        class="text-left"
                        style="min-width:86px;width:86px; max-width:86px"
                      >
                        Ranking
                      </b-th>
                      <b-th
                        width="10%"
                        class="text-left"
                        style="min-width:310px;width:310px; max-width:310px"
                      >
                        justification/remarks
                      </b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr
                      v-for="(po, index) in evaluationItems"
                      :key="index"
                    >
                      <b-td :sticky-column="true">
                        {{ index + 1 }}
                      </b-td>
                      <b-td :sticky-column="true">
                        <span class="text-bold">{{ po.vendorName }}</span>
                        <br>
                        <b-button
                          v-if="canViewThisAction('show', 'PurchaseRequest')"
                          variant="link"
                          class="align-middle p-0 text-left"
                          :to="{ name: 'purchasing-purchase-request-show', params: { id: po.purchaseRequest } }"
                        >
                          {{ po.caseID }}
                        </b-button>
                        <b-button
                          v-else
                          variant="none"
                          class="align-middle p-0 text-left not-button"
                        >
                          {{ po.caseID }}
                        </b-button>
                      </b-td>
                      <b-td>
                        <b-row
                          v-for="(pitem, itemIndex) in po.items"
                          :key="itemIndex"
                          class="mb-50"
                          style="min-width:668px;width:668px; max-width:668px;"
                        >
                          <b-col
                            cols="12"
                            md="7"
                            style="min-width:380px;width:380px; max-width:380px;"
                          >
                            <span class="">{{ pitem }}</span>
                          </b-col>
                          <b-col
                            cols="12"
                            md="1"
                            style="min-width:68px;width:68px; max-width:68px;"
                          >
                            <span class="">{{ po.units[itemIndex] }}</span>
                          </b-col>
                          <b-col
                            cols="12"
                            md="2"
                            style="min-width:100px;width:100px; max-width:100px;"
                          >
                            <span class="">{{ numberFormat(po.unitCosts[itemIndex]) }}</span>
                          </b-col>
                          <b-col
                            cols="12"
                            md="2"
                            style="min-width:120px;width:120px; max-width:120px;"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="adjustedUnitPrices"
                              rules="required"
                            >
                              <b-form-group
                                :label-for="'adjustedUnitPrices' + index + itemIndex"
                                :state="(errors.length > 0) ? false : null"
                              >
                                <b-form-input
                                  :id="'adjustedUnitPrices' + index + itemIndex"
                                  v-model="po.adjustedUnitPrices[itemIndex]"
                                  value="0"
                                  placeholder="0"
                                  :state="(errors.length > 0) ? false : null"
                                  name="adjustedUnitPrices"
                                  @keypress="validateNumberInput"
                                  @paste="validateNumberPaste"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-td>
                      <b-td>
                        <validation-provider
                          #default="{ errors }"
                          name="creditTermDays"
                          rules="required"
                        >
                          <b-form-group
                            :label-for="'creditTermDays' + index"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <b-form-input
                              :id="'creditTermDays' + index"
                              v-model="po.creditTermDays"
                              placeholder="C.O.D"
                              :state="(errors.length > 0) ? false : null"
                              name="creditTermDays"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                      <b-td>
                        <validation-provider
                          #default="{ errors }"
                          name="lateDeliveryDays"
                          rules="required"
                        >
                          <b-form-group
                            :label-for="'lateDeliveryDays' + index"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <b-form-input
                              :id="'lateDeliveryDays' + index"
                              v-model="po.lateDeliveryDays"
                              value="0"
                              placeholder="0"
                              :state="(errors.length > 0) ? false : null"
                              name="lateDeliveryDays"
                              @keypress="validateNumberInput"
                              @paste="validateNumberPaste"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                      <b-td>
                        <validation-provider
                          #default="{ errors }"
                          name="lateDeliveryTimes"
                          rules="required"
                        >
                          <b-form-group
                            :label-for="'lateDeliveryTimes' + index"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <b-form-input
                              :id="'lateDeliveryTimes' + index"
                              v-model="po.lateDeliveryTimes"
                              value="0"
                              placeholder="0"
                              :state="(errors.length > 0) ? false : null"
                              name="lateDeliveryTimes"
                              @keypress="validateNumberInput"
                              @paste="validateNumberPaste"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                      <b-td>
                        <validation-provider
                          #default="{ errors }"
                          name="numberOfNCF"
                          rules="required"
                        >
                          <b-form-group
                            :label-for="'numberOfNCF' + index"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <b-form-input
                              :id="'numberOfNCF' + index"
                              v-model="po.numberOfNCF"
                              value="0"
                              placeholder="0"
                              :state="(errors.length > 0) ? false : null"
                              name="numberOfNCF"
                              @keypress="validateNumberInput"
                              @paste="validateNumberPaste"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                      <b-td>
                        <div style="position: relative;">
                          <validation-provider
                            #default="{ errors }"
                            name="numberOfAdjustedUnitPrice"
                            rules="required"
                          >
                            <b-form-group
                              :label-for="'numberOfAdjustedUnitPrice' + index"
                              :state="(errors.length > 0) ? false : null"
                            >
                              <v-select
                                :id="'numberOfAdjustedUnitPrice' + index"
                                :ref="'numberOfAdjustedUnitPrice' + index"
                                v-model="po.numberOfAdjustedUnitPrice"
                                label="title"
                                :options="zeroToTwoOptions"
                                :reduce="title => title.code"
                                :clearable="false"
                                @input="sumTotalPoint(index, 'numberOfAdjustedUnitPrice')"
                                @open="closePopover(index, 'numberOfAdjustedUnitPrice')"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                          <div
                            v-show="po.numberOfAdjustedUnitPricePopover"
                            role="tooltip"
                            tabindex="-1"
                            class="popover b-popover bs-popover-bottom"
                            x-placement="top"
                            style="position: absolute;will-change: transform;top: 50px;left: 0px;transform: unset;display: block;margin: 0;width: 276px;"
                          >
                            <div
                              class="arrow"
                              style="top: -7px; left: 10px;"
                            />
                            <h3
                              style="background-color: #cd0028; border-color: #cd0028;"
                              class="popover-header"
                            >
                              Maximum Points Reached
                            </h3>
                            <div class="popover-body">
                              This vendor has attained 8 points already. No further points can be given.
                            </div>
                          </div>
                        </div>
                      </b-td>
                      <b-td>
                        <div style="position: relative;">
                          <validation-provider
                            #default="{ errors }"
                            name="numberOfCreditTerm"
                            rules="required"
                          >
                            <b-form-group
                              :label-for="'numberOfCreditTerm' + index"
                              :state="(errors.length > 0) ? false : null"
                            >
                              <v-select
                                :id="'numberOfCreditTerm' + index"
                                :ref="'numberOfCreditTerm' + index"
                                v-model="po.numberOfCreditTerm"
                                label="title"
                                :options="zeroToThreeOptions"
                                :reduce="title => title.code"
                                :clearable="false"
                                @input="sumTotalPoint(index, 'numberOfCreditTerm')"
                                @open="closePopover(index, 'numberOfCreditTerm')"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                          <div
                            v-show="po.numberOfCreditTermPopover"
                            role="tooltip"
                            tabindex="-1"
                            class="popover b-popover bs-popover-bottom"
                            x-placement="top"
                            style="position: absolute;will-change: transform;top: 50px;left: 0px;transform: unset;display: block;margin: 0;width: 276px;"
                          >
                            <div
                              class="arrow"
                              style="top: -7px; left: 10px;"
                            />
                            <h3
                              style="background-color: #cd0028; border-color: #cd0028;"
                              class="popover-header"
                            >
                              Maximum Points Reached
                            </h3>
                            <div class="popover-body">
                              This vendor has attained 8 points already. No further points can be given.
                            </div>
                          </div>
                        </div>
                      </b-td>
                      <b-td>
                        <div style="position: relative;">
                          <validation-provider
                            #default="{ errors }"
                            name="numberOfDelivery"
                            rules="required"
                          >
                            <b-form-group
                              :label-for="'numberOfDelivery' + index"
                              :state="(errors.length > 0) ? false : null"
                            >
                              <v-select
                                :id="'numberOfDelivery' + index"
                                :ref="'numberOfDelivery' + index"
                                v-model="po.numberOfDelivery"
                                label="title"
                                :options="zeroToThreeOptions"
                                :reduce="title => title.code"
                                :clearable="false"
                                @input="sumTotalPoint(index, 'numberOfDelivery')"
                                @open="closePopover(index, 'numberOfDelivery')"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                          <div
                            v-show="po.numberOfDeliveryPopover"
                            role="tooltip"
                            tabindex="-1"
                            class="popover b-popover bs-popover-bottom"
                            x-placement="top"
                            style="position: absolute;will-change: transform;top: 50px;left: 0px;transform: unset;display: block;margin: 0;width: 276px;"
                          >
                            <div
                              class="arrow"
                              style="top: -7px; left: 10px;"
                            />
                            <h3
                              style="background-color: #cd0028; border-color: #cd0028;"
                              class="popover-header"
                            >
                              Maximum Points Reached
                            </h3>
                            <div class="popover-body">
                              This vendor has attained 8 points already. No further points can be given.
                            </div>
                          </div>
                        </div>
                      </b-td>
                      <b-td
                        style="min-width: 100px;"
                      >
                        <div style="position: relative;">
                          <validation-provider
                            #default="{ errors }"
                            name="quantity"
                            rules="required"
                          >
                            <b-form-group
                              :label-for="'quantity' + index"
                              :state="(errors.length > 0) ? false : null"
                            >
                              <v-select
                                :id="'quantity' + index"
                                :ref="'quantity' + index"
                                v-model="po.quantity"
                                label="title"
                                :options="zeroToThreeOptions"
                                :reduce="title => title.code"
                                :clearable="false"
                                @input="sumTotalPoint(index, 'quantity')"
                                @open="closePopover(index, 'quantity')"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </b-form-group>
                          </validation-provider>
                          <div
                            v-show="po.quantityPopover"
                            role="tooltip"
                            tabindex="-1"
                            class="popover b-popover bs-popover-bottom"
                            x-placement="top"
                            style="position: absolute;will-change: transform;top: 50px;left: 0px;transform: unset;display: block;margin: 0;width: 276px;"
                          >
                            <div
                              class="arrow"
                              style="top: -7px; left: 10px;"
                            />
                            <h3
                              style="background-color: #cd0028; border-color: #cd0028;"
                              class="popover-header"
                            >
                              Maximum Points Reached
                            </h3>
                            <div class="popover-body">
                              This vendor has attained 8 points already. No further points can be given.
                            </div>
                          </div>
                        </div>
                      </b-td>
                      <b-td>
                        <validation-provider
                          #default="{ errors }"
                          name="totalPoint"
                          rules="required"
                        >
                          <b-form-group
                            :label-for="'totalPoint' + index"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <b-form-input
                              :id="'totalPoint' + index"
                              v-model="po.totalPoint"
                              value="0"
                              placeholder="0"
                              readonly
                              :state="(errors.length > 0) ? false : null"
                              name="totalPoint"
                              @keypress="validateNumberInput"
                              @paste="validateNumberPaste"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                      <b-td>
                        <validation-provider
                          #default="{ errors }"
                          name="ranking"
                          rules="required"
                        >
                          <b-form-group
                            :label-for="'ranking' + index"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <b-form-input
                              :id="'ranking' + index"
                              v-model="po.ranking"
                              value="0"
                              placeholder="0"
                              readonly
                              :state="(errors.length > 0) ? false : null"
                              name="ranking"
                              @keypress="validateNumberInput"
                              @paste="validateNumberPaste"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-td>

                      <b-td>
                        <validation-provider
                          #default="{ errors }"
                          name="remarks"
                          rules=""
                        >
                          <b-form-group
                            :label-for="'remarks' + index"
                            :state="(errors.length > 0) ? false : null"
                          >
                            <b-form-input
                              :id="'remarks' + index"
                              v-model="po.remarks"
                              placeholder=""
                              :state="(errors.length > 0) ? false : null"
                              name="remarks"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </div>
            </b-card-body>
          </b-card>

          <b-card
            header-tag="header"
          >
            <template #header>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                class="primary-button-with-background mr-3"
              >
                <feather-icon
                  icon="GridIcon"
                  class=""
                  size="20"
                />
              </b-button>
              <h3 class="align-middle mr-auto">
                Signatories
              </h3>
            </template>
            <b-row class="no_Signatories">
              <b-col cols="12">
                <div class="info_item">
                  <feather-icon
                    icon="InfoIcon"
                    class=""
                    size="20"
                  />
                  <span class="align-middle">Please confirm the names of signatories required to sign on this form.</span>
                </div>
              </b-col>
              <b-col cols="md-6">
                <validation-provider
                  #default="{ errors }"
                  name="Number of Signatory Required"
                  vid="signatoryOption"
                  rules="required"
                >
                  <b-form-group
                    label="Number of Signatory Required*"
                    label-for="h-activity-type-signatoryOption"
                    label-cols-md="5"
                    :state="(errors.length > 0 || signatoryOptionValidation) ? false : null"
                  >
                    <v-select
                      id="h-activity-type-signatoryOption"
                      v-model="signatoryOption"
                      label="title"
                      :options="signatoryOptions"
                      :reduce="title => title.code"
                      :clearable="false"
                      @input="changeSignatory()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    <small
                      v-if="signatoryOptionValidation"
                      class="text-danger"
                    >
                      {{ signatoryOptionError }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="md-6" />
            </b-row>

            <hr class="dividerHR">
            <div v-if="signatoryOption">
              <b-row
                v-for="(opt, key) in loas"
                :key="key"
              >
                <b-col
                  v-if="signatoryOption > key"
                  cols="md-6"
                >
                  <validation-provider
                    #default="{ errors }"
                    name=""
                    :vid="'event-custom-field-' + key"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-md="5"
                      :label="opt.title"
                      :label-for="'event-custom-field-' + key"
                      :state="(errors.length > 0) ? false : null"
                    >
                      <v-select
                        id="h-bookings-dutyOfficer"
                        v-model="signatoryAssigns[key]"
                        label="name"
                        placeholder="Select an option"
                        :disabled="key < 1"
                        :options="userOptions"
                        :reduce="name => name._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
          </b-card>

          <b-nav class="wrap-border save-nav">
            <li
              class="nav-item ml-auto"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="flat-primary"
                :disabled="invalid"
                @click="saveAsDraft()"
              >
                Save as Draft
              </b-button>
            </li>
            <li
              class="nav-item mr-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="success"
                :disabled="invalid"
                @click="enablePreview()"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50"
                />
                <span class="align-middle">Preview Before Submitting</span>
              </b-button>
            </li>
          </b-nav>
        </validation-observer>
      </b-form>
    </div>

    <b-modal
      id="modal-evaluation-guide"
      size="sm"
      title="Evaluation Guide"
      modal-class="status-update-modal"
      centered
      no-stacking
      hide-footer
      header-class="front-modal"
    >
      <b-card-text class="m-0">
        <h6 class="text-bold">
          Weighting
        </h6>
        <span class="text-bold">1</span> - Good<br>
        <span class="text-bold">2</span> - Acceptable<br>
        <span class="text-bold">3</span> - Non-Acceptable
      </b-card-text>
      <hr class="dividerHR filter-divider">
      <b-card-text class="mb-2">
        <h6 class="text-bold">
          Adj. Unit Price
        </h6>
        <span>By comparison of price and term of payment.</span>
      </b-card-text>
      <b-card-text class="mb-2">
        <h6 class="text-bold">
          Credit Term
        </h6>
        <span>30 - 45 days considered acceptable, above 45 days considered good.</span>
      </b-card-text>
      <b-card-text class="mb-2">
        <h6 class="text-bold">
          Delivery / Service
        </h6>
        <span>Delayed by 1 to 2 days considered acceptable, delayed by 1 to 2 times (each time from 3 to 5 days) witnin 6 months considered non-acceptable</span>
      </b-card-text>
      <b-card-text class="mb-2">
        <h6 class="text-bold">
          Quality
        </h6>
        <span>1 to 2 non-conformity within 3 months considered acceptable.</span>
      </b-card-text>
      <b-alert
        show
        variant="secondary"
      >
        <div class="alert-body text-center">
          <span class="text-bold">Total Points Acceptable – 8 Points</span>
        </div>
      </b-alert>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BForm, BButton, BNav, BCardText, BDropdown, BDropdownItem, BFormInput,
  BFormCheckbox, BCardBody, BTableSimple, BTbody, BTr, BTh, BTd, BImg, BAlert, BThead,
} from 'bootstrap-vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import store from '@/store/index'
// import { jsPDF } from 'jspdf'
// import html2canvas from 'html2canvas'
// import html2pdf from 'html2pdf.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { heightTransition } from '@core/mixins/ui/transition'

const { canViewThisAction } = useAclUtils()

export default {
  components: {
    BFormInput,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BNav,
    BFormCheckbox,
    BCardBody,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BCardText,
    BDropdown,
    BDropdownItem,
    BImg,
    BAlert,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      canViewThisAction,
      evaluationPreview: false,
      secondAgree: '',
      user: store.state.auth.userData,
      aveForm: {},
      signatoryOption: 3,
      signatoryOptionOld: 3,
      attachments: [],
      signatoryOptionError: 'Valid Number of Signatory is required',
      signatoryOptionValidation: false,
      userOptions: [],
      businessEntityOptions: [],
      saveType: 'preview',
      entityString: '',
      signatoryAssigns: [],
      signatoryAssignsError: 'Valid Signatory is required',
      signatoryAssignsValidation: false,
      signatoryOptions: [
        { title: '3', code: '3' },
      ],
      zeroToTwoOptions: [
        { title: '0', code: 0 },
        { title: '1', code: 1 },
        { title: '2', code: 2 },
      ],
      zeroToThreeOptions: [
        { title: '0', code: 0 },
        { title: '1', code: 1 },
        { title: '2', code: 2 },
        { title: '3', code: 3 },
      ],
      loas: [
        { title: '1. Prepared by', code: 'Prepared by' },
        { title: '2. Confirmed by', code: 'Confirmed by' },
        { title: '3. Approved by', code: 'Approved by' },
      ],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://httpbin.org/post',
        acceptedFiles: 'image/jpeg,image/png,application/pdf',
        maxFilesize: 5,
        addRemoveLinks: true,
        dictFileTooBig: 'File is too big ({{filesize}}mb). Max filesize: {{maxFilesize}}mb.',
        // maxFiles: 1,
      },
      fileName: 'compressed.tracemonkey-pldi-09.pdf',
      path: '/lib/pdfjs/web/viewer.html',
      pdfData: '',
      pdfTotalPage: 0,
      elHeight: 0,
      gapHeight: 0,
      showPDF: true,
      showAttachment: false,
      selectedFileName: '',
      currentFileIndex: 1,
      imageSrc: '',
      chunkEvaluationItems: [],
      chunksOfEvaluationItems: [],
      evaluationItems: [],
      evaluatedPOs: [],

      // validation rules
      required,
    }
  },

  created() {
    this.$http.get(`purchase/vendor-evaluations/update/${this.$route.params.id}/detail`)
      .then(response => {
        this.aveForm = response.data.evaluation ?? {}
        this.evaluatedPOs = response.data.evaluationItems ?? []
        this.userOptions = response.data.userOptions ?? []
        let string = ''
        this.aveForm.entity.forEach((item, key) => {
          if (key === 0) {
            string += item.name
          } else {
            string += ` / ${item.name}`
          }
        })

        this.entityString = string
        response.data.evaluationItems.forEach(po => {
          const obj = {
            id: po._id,
            purchaseRequest: po.purchaseRequest,
            caseID: po.caseID,
            purchaseOrder: po.purchaseOrder,
            vendor: po.vendor,
            vendorName: po.vendorName,
            items: po.items,
            units: po.units,
            unitCosts: po.unitCosts,
            adjustedUnitPrices: po.adjustedUnitPrices,
            creditTermDays: po.creditTermDays,
            lateDeliveryDays: po.lateDeliveryDays,
            lateDeliveryTimes: po.lateDeliveryTimes,
            numberOfNCF: po.numberOfNCF,
            numberOfAdjustedUnitPrice: po.numberOfAdjustedUnitPrice,
            numberOfAdjustedUnitPricePopover: false,
            numberOfCreditTerm: po.numberOfCreditTerm,
            numberOfCreditTermPopover: false,
            numberOfDelivery: po.numberOfDelivery,
            numberOfDeliveryPopover: false,
            quantity: po.quantity,
            quantityPopover: false,
            totalPoint: po.totalPoint,
            ranking: po.ranking,
            remarks: po.remarks,
          }

          this.evaluationItems.push(obj)
        })

        this.signatoryOption = 3
        this.signatoryOptionOld = this.signatoryOption
        this.aveForm.signatories.forEach((arrayItem, key) => {
          if (key <= 3) {
            this.signatoryAssigns.push(arrayItem.user._id)
          }
        })
        this.selectedFileName = this.aveForm.stringID
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  beforeRouteLeave(to, from, next) {
    const bcLength = store.state.breadcrumb.breadcrumbs.length
    if (bcLength > 3) {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
    }
    next()
  },

  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    validateNumberInput(event) {
      // if (caseName) {
      //   if (event.key && !/^\d*$/.test(event.key)) {
      //     event.preventDefault()
      //   }

      //   if (caseName === 'numberOfAdjustedUnitPrice') {
      //     if (event.key > 2 || this.evaluationItems[index].numberOfAdjustedUnitPrice.length === 1) {
      //       event.preventDefault()
      //     }
      //   } else if (caseName === 'numberOfCreditTerm') {
      //     if (event.key > 3 || this.evaluationItems[index].numberOfCreditTerm.length === 1) {
      //       event.preventDefault()
      //     }
      //   } else if (caseName === 'numberOfDelivery') {
      //     if (event.key > 3 || this.evaluationItems[index].numberOfDelivery.length === 1) {
      //       event.preventDefault()
      //     }
      //   } else if (caseName === 'quantity') {
      //     if (event.key > 3 || this.evaluationItems[index].quantity.length === 1) {
      //       event.preventDefault()
      //     }
      //   }
      // } else if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
      //   event.preventDefault()
      // }
      if (event.key && !/^-?\d*[.]?\d*$/.test(event.key)) {
        event.preventDefault()
      }
    },
    validateNumberPaste(event) {
      const text = (event.originalEvent || event).clipboardData.getData('text/plain')
      if (text && !/^-?\d*[.]?\d*$/.test(text)) {
        event.preventDefault()
      }
    },
    saveAsDraft() {
      this.saveType = 'draft'
      this.submitForm()
    },
    closePopover(index, caseName) {
      if (caseName === 'numberOfAdjustedUnitPrice') {
        this.evaluationItems[index].numberOfAdjustedUnitPricePopover = false
      } else if (caseName === 'numberOfCreditTerm') {
        this.evaluationItems[index].numberOfCreditTermPopover = false
      } else if (caseName === 'numberOfDelivery') {
        this.evaluationItems[index].numberOfDeliveryPopover = false
      } else if (caseName === 'quantity') {
        this.evaluationItems[index].quantityPopover = false
      }
    },
    async sumTotalPoint(index, caseName) {
      await this.$nextTick()
      this.evaluationItems[index].numberOfAdjustedUnitPricePopover = false
      this.evaluationItems[index].numberOfCreditTermPopover = false
      this.evaluationItems[index].numberOfDeliveryPopover = false
      this.evaluationItems[index].quantityPopover = false
      let total = this.evaluationItems[index].numberOfAdjustedUnitPrice + this.evaluationItems[index].numberOfCreditTerm + this.evaluationItems[index].numberOfDelivery + this.evaluationItems[index].quantity
      if (total > 8) {
        if (caseName === 'numberOfAdjustedUnitPrice') {
          this.evaluationItems[index].numberOfAdjustedUnitPrice = 0
          this.evaluationItems[index].numberOfAdjustedUnitPricePopover = true
        } else if (caseName === 'numberOfCreditTerm') {
          this.evaluationItems[index].numberOfCreditTerm = 0
          this.evaluationItems[index].numberOfCreditTermPopover = true
        } else if (caseName === 'numberOfDelivery') {
          this.evaluationItems[index].numberOfDelivery = 0
          this.evaluationItems[index].numberOfDeliveryPopover = true
        } else if (caseName === 'quantity') {
          this.evaluationItems[index].quantity = 0
          this.evaluationItems[index].quantityPopover = true
        }
      }

      total = this.evaluationItems[index].numberOfAdjustedUnitPrice + this.evaluationItems[index].numberOfCreditTerm + this.evaluationItems[index].numberOfDelivery + this.evaluationItems[index].quantity
      this.evaluationItems[index].totalPoint = total
      if (total === 8) {
        this.evaluationItems[index].ranking = 2
      } else {
        this.evaluationItems[index].ranking = 1
      }
    },
    confirmBeforeLeaving() {
      this.$swal({
        title: 'Are you sure?',
        html: 'You will have to start over the selection process of vendors again if you want to change the evaluation dates.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, resume scoring',
        confirmButtonText: 'Yes, I need to change',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        width: '500',
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-vendors-generate-evaluation' })
          }
        })
    },
    base64ToUint8Array(base64) {
      const raw = atob(base64)
      const uint8Array = new Uint8Array(raw.length)
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < raw.length; i++) {
        uint8Array[i] = raw.charCodeAt(i)
      }
      return uint8Array
    },
    changeFileIndex(key) {
      this.currentFileIndex = key
      if (key === 1) {
        this.selectedFileName = this.companyName
        this.showAttachment = false
      } else {
        this.selectedFileName = this.attachments[key - 2].name
        if (this.attachments[key - 2].type.includes('pdf')) {
          const base64Raw = this.attachments[key - 2].data.split(',')[1]
          const pdfDataJS = this.base64ToUint8Array(base64Raw)
          const pdfViewerFrame2 = document.getElementById('attachmentpdf')
          pdfViewerFrame2.onload = () => {
            pdfViewerFrame2.contentWindow.PDFViewerApplication.open(pdfDataJS)
          }
          pdfViewerFrame2.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.showPDF = true
        } else {
          this.imageSrc = this.attachments[key - 2].data
          this.showPDF = false
        }
        this.showAttachment = true
      }
    },
    async enablePreview() {
      // localStorage.setItem('veData', JSON.stringify(this.evaluationItems))
      // localStorage.setItem('aveForm', JSON.stringify(this.aveForm))
      const signatoryArray = []
      this.signatoryAssigns.forEach((user, key) => {
        const userObj = {
          code: this.loas[key].code,
          name: this.resolveSignatoryName(user),
          department: this.resolveSignatoryDepartment(user),
        }
        signatoryArray.push(userObj)
      })
      // localStorage.setItem('signatoryAssigns', JSON.stringify(signatoryArray))
      // localStorage.setItem('userOptions', JSON.stringify(this.userOptions))
      // localStorage.setItem('entityString', this.entityString)
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const newMeta = {
        active: true,
        text: 'Preview',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      // store.commit('appConfig/UPDATE_LOADER', true)
      // const vm = this
      // await this.calculateTotalPage()
      // const element = document.getElementById('pdfGenerateDiv')
      // const opt = {
      //   margin: 1,
      //   filename: 'myfile.pdf',
      //   pagebreak: { mode: ['avoid-all', 'css', 'legacy'], after: '.page-section' },
      //   image: { type: 'jpeg', quality: 1 },
      //   html2canvas: {
      //     scale: 2,
      //     dpi: 192,
      //     // windowWidth: 1024,
      //     letterRendering: true,
      //   },
      //   jsPDF: { unit: 'mm', format: [550, 297], orientation: 'l' },
      // }

      // html2pdf().set(opt).from(element).toPdf()
      //   .get('pdf')
      //   .then(pdf => {
      //     vm.pdfData = pdf.output('datauristring')
      //     const base64Raw = vm.pdfData.split(',')[1]
      //     const pdfDataJS = vm.base64ToUint8Array(base64Raw)
      //     const pdfViewerFrame = document.getElementById('docpdf')
      //     // eslint-disable-next-line func-names
      //     pdfViewerFrame.onload = () => {
      //       pdfViewerFrame.contentWindow.PDFViewerApplication.open(pdfDataJS)
      //     }
      //     pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
      //     vm.currentFileIndex = 1
      //     vm.selectedFileName = vm.companyName
      //     vm.showAttachment = false
      //     window.scrollTo(0, 0)
      //     vm.evaluationPreview = true
      //     store.commit('appConfig/UPDATE_LOADER', false)
      //   })
      const formData = new FormData()
      formData.append('url', `${window.location.origin}/vendors/evaluation/preview`)
      // formData.append('url', `${window.location.origin}/purchase-request/download/po/65599c5db44ced55532da059`)
      formData.append('landscape', true)
      const localStorageObj = {
        veData: this.evaluationItems,
        aveForm: this.aveForm,
        signatoryAssigns: signatoryArray,
        entityString: this.entityString,
      }
      formData.append('localStorageObj', JSON.stringify(localStorageObj))
      // formData.append('aveForm', JSON.stringify(this.aveForm))
      // formData.append('signatoryAssigns', JSON.stringify(signatoryArray))
      // formData.append('entityString', this.entityString)

      this.$http.post('purchase/vendor/evaluation/pdf/download', formData, {
        headers: { Accept: 'application/pdf' },
        responseType: 'arraybuffer',
      })
        .then(response => {
          const uint8View = new Uint8Array(response.data)
          const pdfViewerFrame = document.getElementById('docpdf')
          // eslint-disable-next-line func-names
          pdfViewerFrame.onload = () => {
            pdfViewerFrame.contentWindow.PDFViewerApplication.open(uint8View)
          }
          pdfViewerFrame.setAttribute('src', '/lib/pdfjs/web/viewer.html?file=')
          this.currentFileIndex = 1
          // this.selectedFileName = this.companyName
          this.showAttachment = false
          window.scrollTo(0, 0)
          this.evaluationPreview = true
          store.commit('appConfig/UPDATE_LOADER', false)
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    async calculateTotalPage() {
      this.pdfTotalPage = 0
      this.chunksOfEvaluationItems = []
      this.chunkEvaluationItems = []
      const logoHeight = document.getElementById('main-header').offsetHeight
      const footerHeight = document.getElementById('footer-div').offsetHeight
      let contentHeight = document.getElementById('content-div').offsetHeight
      let totalItems = this.evaluationItems.length
      // let i = 0
      let sliceStart = 0
      let sliceEnd = 10
      while (totalItems > 0) {
        contentHeight = 1080 - (logoHeight + footerHeight)
        // if (i === 0) {
        //   contentHeight = 1000 - (logoHeight + footerHeight)
        // } else {
        //   contentHeight = 1000 - (logoHeight + footerHeight)
        // }
        // console.log(contentHeight)
        this.chunkEvaluationItems = this.evaluationItems.slice(sliceStart, sliceEnd)
        // this.$nextTick(() => {
        //   console.log(document.getElementById('content-div').offsetHeight)
        // })
        // eslint-disable-next-line no-await-in-loop
        await this.$nextTick()
        let tableHeight = document.getElementById('content-div').offsetHeight
        // console.log(tableHeight)
        if (tableHeight > contentHeight) {
          contentHeight += footerHeight
          // contentHeight += vendorAndDeliveryDetailHeight
        }
        // console.log(tableHeight, contentHeight)
        while (tableHeight > contentHeight) {
          sliceEnd -= 1
          // console.log(contentHeight, tableHeight)
          this.chunkEvaluationItems = this.evaluationItems.slice(sliceStart, sliceEnd)
          // console.log(sliceStart, sliceEnd)
          // eslint-disable-next-line no-await-in-loop
          await this.$nextTick()
          tableHeight = document.getElementById('content-div').offsetHeight
          // console.log(tableHeight, contentHeight)
        }
        // console.log(tableHeight)
        // console.log(sliceEnd - sliceStart)
        // console.log(this.chunkEvaluationItems.length)
        this.chunksOfEvaluationItems.push(this.chunkEvaluationItems)
        this.pdfTotalPage += 1
        // i += 1
        sliceStart = sliceEnd
        sliceEnd += 10
        totalItems -= this.chunkEvaluationItems.length
      }
      let remainingHeight = 0
      const dHeight = 1080
      contentHeight = document.getElementById('content-div').offsetHeight
      remainingHeight = dHeight - (logoHeight + footerHeight + contentHeight)
      if (remainingHeight > 0) {
        this.gapHeight = remainingHeight
      }
      if (contentHeight >= (dHeight - (logoHeight + footerHeight))) {
        this.pdfTotalPage += 1
        this.gapHeight = dHeight - (logoHeight + footerHeight)
      }
    },
    enableEdit() {
      store.commit('breadcrumb/REMOVE_BREADCRUMB')
      const newMeta = {
        active: true,
        text: 'Annual Vendor Evaluation - Scoring',
      }
      store.commit('breadcrumb/UPDATE_BREADCRUMB', newMeta)
      this.evaluationPreview = false
    },
    resolveItemCounter(page, index) {
      let count = 0
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < (page - 1); i++) {
        count += this.chunksOfEvaluationItems[i].length
      }

      count += index + 1

      return count
    },
    finalConfirmation() {
      this.$swal({
        title: `Submit this Annual Vendor Evaluation ${this.aveForm.period}?`,
        html: `You are proceeding to sign this evaluation. This Annual Vendor Evaluation will then be ready for ${this.resolveNextSignatoryName()} for signing.`,
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/fly.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, go back',
        confirmButtonText: 'Yes, Proceed',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
    },
    resolveSignatoryName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name
      }

      return ''
    },
    resolveSignatoryDepartment(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.department.length ? user.department[0].name : ''
      }

      return ''
    },
    resolveNextSignatoryName() {
      const user = this.userOptions.find(o => o._id === this.signatoryAssigns[1])
      if (user) {
        return user.name
      }

      return ''
    },
    async changeSignatory() {
      // eslint-disable-next-line radix
      this.signatoryOption = parseInt(this.signatoryOption)
      if (this.signatoryOptionOld > this.signatoryOption) {
        await this.$nextTick()
        this.$swal({
          title: 'Are You Sure?',
          html: 'You are changing the number of signatory.',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.png'),
          reverseButtons: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: 'No, go back',
          confirmButtonText: "Yes, I'm sure.",
          customClass: {
            confirmButton: 'btn btn-danger ml-1',
            cancelButton: 'btn btn-outline-primary',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              this.signatoryOptionOld = this.signatoryOption
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            } else {
              this.signatoryOption = this.signatoryOptionOld
              // eslint-disable-next-line radix
              const intOption = parseInt(this.signatoryOption)
              if (this.signatoryAssigns.length > intOption) {
                this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
              }
            }
          })
      } else {
        this.signatoryOptionOld = this.signatoryOption
        // eslint-disable-next-line radix
        const intOption = parseInt(this.signatoryOption)
        if (this.signatoryAssigns.length > intOption) {
          this.signatoryAssigns = this.signatoryAssigns.slice(0, intOption)
        }
      }
    },
    submitForm() {
      this.$refs.vendorEvaluationForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('url', window.location.origin)
          formData.append('status', this.saveType)
          formData.append('signatoryOption', this.signatoryOption)
          formData.append('evaluationItems', JSON.stringify(this.evaluationItems))
          formData.append('signatoryAssigns', JSON.stringify(this.signatoryAssigns))

          this.$http.patch(`purchase/vendor-evaluations/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.message) {
                if (this.saveType === 'preview') {
                  this.$swal({
                    title: 'Submitted for Approval',
                    html: `This Annual Vendor Evaluation ${this.aveForm.period} will now be sent to the next assigned signatory (${this.resolveNextSignatoryName()}) for signing.`,
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/success.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'View Entry',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.$router.push({ name: 'purchasing-vendors-evaluation-detail', params: { id: this.$route.params.id } })
                      }
                    })
                } else {
                  this.$swal({
                    title: 'Draft Saved',
                    html: 'You can continue editing this Annual Vendor Evaluation at a later time before sending it out for approval.',
                    // eslint-disable-next-line global-require
                    imageUrl: require('@/assets/images/icons/save.png'),
                    imageWidth: 80,
                    imageHeight: 80,
                    imageAlt: 'Custom Icon',
                    showCancelButton: false,
                    allowOutsideClick: false,
                    confirmButtonText: 'Okay',
                    customClass: {
                      confirmButton: 'btn btn-primary mr-1',
                      cancelButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                    .then(result => {
                      if (result.value) {
                        this.$router.push({ name: 'purchasing-vendors-evaluation-detail', params: { id: this.$route.params.id } })
                      }
                    })
                }
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'companyName') {
                    this.companyNameError = validationError.msg
                    this.companyNameValidation = true
                  } else if (validationError.param === 'companyContact') {
                    this.companyContactError = validationError.msg
                    this.companyContactValidation = true
                  } else if (validationError.param === 'companyRegistration') {
                    this.companyRegistrationError = validationError.msg
                    this.companyRegistrationValidation = true
                  } else if (validationError.param === 'companyFax') {
                    this.companyFaxError = validationError.msg
                    this.companyFaxValidation = true
                  } else if (validationError.param === 'companyGSTRegistration') {
                    this.companyGSTRegistrationError = validationError.msg
                    this.companyGSTRegistrationValidation = true
                  } else if (validationError.param === 'companyAddress') {
                    this.companyAddressError = validationError.msg
                    this.companyAddressValidation = true
                  } else if (validationError.param === 'individualNRIC') {
                    this.individualNRICError = validationError.msg
                    this.individualNRICValidation = true
                  } else if (validationError.param === 'salesPersonName') {
                    this.salesPersonNameError = validationError.msg
                    this.salesPersonNameValidation = true
                  } else if (validationError.param === 'salesPersonEmail') {
                    this.salesPersonEmailError = validationError.msg
                    this.salesPersonEmailValidation = true
                  } else if (validationError.param === 'salesPersonContact') {
                    this.salesPersonContactError = validationError.msg
                    this.salesPersonContactValidation = true
                  } else if (validationError.param === 'salesPersonEmail2') {
                    this.salesPersonEmail2Error = validationError.msg
                    this.salesPersonEmail2Validation = true
                  } else if (validationError.param === 'relativeInNirvana') {
                    this.relativeInNirvanaError = validationError.msg
                    this.relativeInNirvanaValidation = true
                  } else if (validationError.param === 'paymentTerms') {
                    this.paymentTermsError = validationError.msg
                    this.paymentTermsValidation = true
                  } else if (validationError.param === 'paymentTermsDescription') {
                    this.paymentTermsDescriptionError = validationError.msg
                    this.paymentTermsDescriptionValidation = true
                  } else if (validationError.param === 'accountPayeeName') {
                    this.accountPayeeNameError = validationError.msg
                    this.accountPayeeNameValidation = true
                  } else if (validationError.param === 'bankAccountNo') {
                    this.bankAccountNoError = validationError.msg
                    this.bankAccountNoValidation = true
                  } else if (validationError.param === 'bankName') {
                    this.bankNameError = validationError.msg
                    this.bankNameValidation = true
                  } else if (validationError.param === 'bankAddress') {
                    this.bankAddressError = validationError.msg
                    this.bankAddressValidation = true
                  } else if (validationError.param === 'bankBranch') {
                    this.bankBranchError = validationError.msg
                    this.bankBranchValidation = true
                  } else if (validationError.param === 'accountCurrency') {
                    this.accountCurrencyError = validationError.msg
                    this.accountCurrencyValidation = true
                  } else if (validationError.param === 'swiftCode') {
                    this.swiftCodeError = validationError.msg
                    this.swiftCodeValidation = true
                  } else if (validationError.param === 'bankEmail') {
                    this.bankEmailError = validationError.msg
                    this.bankEmailValidation = true
                  } else if (validationError.param === 'certificates') {
                    this.certificatesError = validationError.msg
                    this.certificatesValidation = true
                  } else if (validationError.param === 'signatoryOption') {
                    this.signatoryOptionError = validationError.msg
                    this.signatoryOptionValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
  .text-bold {
    font-weight: 600;
    font-size: 14px;
    color: #333333;
  }
  .zero-padding {
    padding: 0 !important;
  }
  .table td {
    padding: 12px 10px !important;
  }
  .table thead th {
    padding: 12px 10px !important;
  }
  .table th, .table td {
    vertical-align: top;
  }
  .bs-popover-bottom > .arrow::before, [dir] .bs-popover-auto[x-placement^=bottom] > .arrow::before {
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #cd0028;
  }

  .popover.bs-popover-bottom .arrow:after, [dir] .popover.bs-popover-auto[x-placement^=bottom] .arrow:after {
    border-bottom-color: #cd0028;
  }
</style>
